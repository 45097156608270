<template>
  <section style="margin: 0 10px; clear: both" v-if="panelOptions">
    <DataListForm
      v-model="dataList"
      :selectable="false"
      :dataListParser="dataListParser"
    />
    <TogglePanel
      :title="$tc('columns', 1)"
      :icon="{
        collapse: 'fa-caret-square-o-up',
        expand: 'fa-caret-square-o-down'
      }"
    >
      <div class="panel-content">
        <FieldListForm v-model="fields" />
      </div>
    </TogglePanel>
  </section>
</template>

<script>
import {isEqual} from "lodash";
import Panels from "@/assets/dashboard/panels.json";
import DataListForm from "@/components/control-sidebar/property-editors/data-list-form.vue";
import FieldListForm from "@/components/control-sidebar/property-editors/field-list-form.vue";
import TogglePanel from "@/components/control-sidebar/toggle-panel.vue";
const defDataPanel = () => {
  return Panels.find(
    (panel) => panel.template.template == "EquipmentDataPanel"
  );
};

const defDataFields = () => {
  return JSON.parse(JSON.stringify(defDataPanel().template.options.fields));
};

export {defDataPanel, defDataFields};

export default {
  name: "DetailFormData",
  components: {
    DataListForm,
    FieldListForm,
    TogglePanel
  },
  data() {
    return {
      panelOptions: null
    };
  },
  computed: {
    panel() {
      return this.$store.getters["dashboard/currentDraftPanel"] || null;
    },
    screenId() {
      return (this.$store.getters["dashboard/draft"] || {screenId: ""})
        .screenId;
    },
    dataList: {
      set(values) {
        this.$set(this.panelOptions, "dataList", values);
      },
      get() {
        return this?.panelOptions?.dataList || [];
      }
    },
    fields: {
      set(value) {
        this.$set(
          this.panelOptions,
          "fields",
          JSON.parse(JSON.stringify(value))
        );
      },
      get() {
        return this?.panelOptions?.fields || defDataFields();
      }
    }
  },
  watch: {
    panelOptions: {
      // handler(n, o) {
      //   if (n && o && !isEqual(n, o)) {
      //     this.$store.dispatch("dashboard/saveDraftPanel", {
      //       panel: this.panel,
      //       screenId: this.screenId
      //     });
      //   }
      // },
      // deep: true
      handler(n) {
        if (n) {
          this.$store.dispatch("dashboard/saveDraftPanel", {
            panel: {
              ...this.panel,
              options: {
                ...this.panel.options,
                ...this.panelOptions
              }
            },
            screenId: this.screenId
          });
        }
      },
      deep: true
    }
  },
  methods: {
    dataListParser(lst) {
      return (lst || []).filter(
        ({id}) => `${id}`.includes("data_group") || /^\d+$/g.test(id)
      );
    }
  },
  created() {
    this.panelOptions = this?.panel?.options || null;
  }
};
</script>

<style scoped>
.box-body > .content {
  min-height: inherit;
}

div.option {
  margin: 3px;
  white-space: nowrap;
}

div.option > i {
  margin-right: 3px;
}

.import-options {
  padding: 0;
  margin: 0;
  text-align: center;
  white-space: nowrap;
}

.import-options > span.btn {
  margin: 0 5px 0 0;
}

div.data-list {
  margin-top: -10px;
}

div.item {
  position: relative;
  width: 100%;
  font-size: 1em;
  border-top: 1px solid #e4e4e4;
  z-index: 1;
}

div.item-title {
  padding: 4px 22px 4px 2px;
  font-weight: 600;
  white-space: normal;
  width: 100%;
}

div.item-toolbar {
  position: absolute;
  top: 4px;
  right: 5px;
  z-index: 4;
}

div.item-toolbar > i {
  padding: 0 4px;
  min-width: 22px;
}

.clicable:hover {
  cursor: pointer;
  opacity: 0.8;
}
</style>
